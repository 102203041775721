<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import validation from '@/utils/validation'
import { getCountryOptions, getStateOptions } from '@/utils/countries-states'
import { authAPI } from '@/api/auth-api'
import {
    formLabels,
    formPlaceholders,
    productFamily
} from '@/constants/deal-registration'
import { dealTypeOptions } from '@/constants/deals'
import { getExternalErrors } from '@/utils/formatting'
import { permissionsEnum } from '@/constants/rbac/permissions'
import { checkHasPermissions } from '@/utils/permissions'

const states = getStateOptions()
const countries = getCountryOptions({ preferredCountries: ['us'] })
const USA = 'United States' // TODO refactoring (centralize)

const emit = defineEmits(['success'])

const store = useStore()
const currentUser = computed(() => store.getters.currentUser)
const externalErrors = ref({})
const autoDirty = ref(false)
const error = ref({
    isError: false,
    errorText: ''
})

const users = ref([])
const totalUsersCount = ref(null)
const usersOptionsLoading = ref(false)
const prepareUsersOptions = (users) => {
    return users.map((user) => ({ value: user._id, text: user.name }))
}

const getUsers = (params) => {
    usersOptionsLoading.value = true
    authAPI
        .fetchUsers(params)
        .then((res) => {
            console.log('getUsers res: ', res)
            if (res.results) {
                users.value = [
                    ...users.value,
                    ...prepareUsersOptions(res.results)
                ]

                if (!totalUsersCount.value) {
                    totalUsersCount.value = res._meta[0].count
                }
            }
        })
        .finally(() => {
            usersOptionsLoading.value = false
        })
}

getUsers()

const onIntersectionUsers = () => {
    const offset = users.value.length

    if (totalUsersCount.value > offset) {
        getUsers({ offset })
    }
}

const preparedUsers = computed(() => {
    const [currentUserOption] = prepareUsersOptions([currentUser.value])

    const filteredUsersOptions = users.value.filter(
        (u) => u.value !== currentUser.value._id
    )
    return [currentUserOption, ...filteredUsersOptions]
})

const getInitForm = () => {
    return {
        createdBy: currentUser.value._id,
        customerCompany: '',
        customerFirstName: '',
        customerLastName: '',
        customerEmail: '',
        country: '',
        state: '',
        closeDate: '',
        amount: '',
        type: '',
        productFamily: [],
        description: ''
    }
}
const form = ref(getInitForm())

const rules = computed(() => {
    return {
        createdBy: {
            required: validation.required(formLabels.createdBy)
        },
        customerCompany: {
            required: validation.required(formLabels.customerCompany)
        },
        customerFirstName: {
            required: validation.required(formLabels.customerFirstName)
        },
        customerLastName: {
            required: validation.required(formLabels.customerLastName)
        },
        customerEmail: {
            required: validation.required(formLabels.customerEmail),
            validEmail: validation.validEmail
        },
        country: {
            required: validation.required(formLabels.country)
        },
        state: {
            requiredIf: validation.requiredIf(
                formLabels.state,
                () => form.value.country === USA
            )
        },
        closeDate: {
            required: validation.required(formLabels.closeDate)
        },
        amount: {
            required: validation.required(formLabels.amount),
            minValue: validation.minValue(formLabels.amount, 1)
        },
        type: {
            required: validation.required(formLabels.type)
        },
        productFamily: {
            required: validation.required(formLabels.productFamily)
        },
        description: {
            maxLength: validation.maxLength(formLabels.description, 255)
        }
    }
})

const handlerForm = async ({
    customerFirstName,
    customerLastName,
    amount,
    state,
    ...form
}) => {
    try {
        const preparedForm = {
            ...form,
            customer: `${customerFirstName} ${customerLastName}`,
            amount: +amount,
            state: form.country === USA ? state : ''
        }

        const res = await store.dispatch('admin/registerDeal', preparedForm)
        console.log('Deal form res: ', res)

        if (res?.data) {
            emit('success', preparedForm)
        } else if (res?.errInfo.status === 400) {
            autoDirty.value = true
            externalErrors.value = getExternalErrors(res.errInfo.errors)
        } else {
            throw new Error()
        }
    } catch (err) {
        error.value.isError = true
        error.value.errorText = 'An error occurred while creating a new deal'
    }
}
const resetForm = () => Object.assign(form.value, getInitForm())
</script>

<script>
export default {
    name: 'DealRegistrationFormForAdmin'
}
</script>

<template>
    <BaseForm
        :form="form"
        :rules="rules"
        submit-text="Add New Deal"
        :on-submit="handlerForm"
        :error="error.isError"
        :error-text="error.errorText"
        :external-errors="externalErrors"
        :auto-dirty="autoDirty"
        reset-form
        :on-reset="resetForm"
        class="deal-form"
    >
        <template #default="{ validator }">
            <BaseSelect
                :options="preparedUsers"
                v-model="form.createdBy"
                :validator="validator.createdBy"
                :name="formLabels.createdBy"
                :placeholder="formPlaceholders.createdBy"
                :options-loading="usersOptionsLoading"
                required
                intersection
                @intersection="onIntersectionUsers"
            />
            <BaseInput
                type="text"
                v-model="form.customerCompany"
                :validator="validator.customerCompany"
                :name="formLabels.customerCompany"
                :placeholder="formPlaceholders.customerCompany"
                required
                autocomplete="off"
            />
            <BaseInput
                type="text"
                v-model="form.customerFirstName"
                :validator="validator.customerFirstName"
                :name="formLabels.customerFirstName"
                :placeholder="formPlaceholders.customerFirstName"
                required
                autocomplete="off"
            />
            <BaseInput
                type="text"
                v-model="form.customerLastName"
                :validator="validator.customerLastName"
                :name="formLabels.customerLastName"
                :placeholder="formPlaceholders.customerLastName"
                required
                autocomplete="off"
            />
            <BaseInput
                type="text"
                v-model="form.customerEmail"
                :validator="validator.customerEmail"
                :name="formLabels.customerEmail"
                :placeholder="formPlaceholders.customerEmail"
                required
                autocomplete="off"
            />
            <BaseSelect
                :options="countries"
                v-model="form.country"
                :validator="validator.country"
                :name="formLabels.country"
                :placeholder="formPlaceholders.country"
                required
                searchable
            />
            <BaseSelect
                v-if="form.country === USA"
                :options="states"
                v-model="form.state"
                :validator="validator.state"
                :name="formLabels.state"
                :placeholder="formPlaceholders.state"
                required
                searchable
            />
            <BaseCheckboxBox
                title="Product/Products Interesting in"
                v-model="form.productFamily"
                :options="productFamily"
                :validator="validator.productFamily"
                required
                class="deal-form__checkbox-box"
            />
            <BaseDate
                v-model="form.closeDate"
                :validator="validator.closeDate"
                :placeholder="formPlaceholders.closeDate"
                :name="formLabels.closeDate"
                required
            />
            <BaseInput
                type="currency"
                v-model="form.amount"
                :validator="validator.amount"
                :name="formLabels.amount"
                :placeholder="formPlaceholders.amount"
                required
                autocomplete="off"
            />
            <BaseSelect
                :options="dealTypeOptions"
                v-model="form.type"
                :validator="validator.type"
                :name="formLabels.type"
                :placeholder="formPlaceholders.type"
                required
            />
            <BaseInput
                type="textarea"
                v-model="form.description"
                :validator="validator.description"
                :name="formLabels.description"
                :placeholder="formPlaceholders.description"
            />
        </template>
        <template #button="{ loading, invalid, submitText, validator }">
            <BaseButton
                v-if="checkHasPermissions([permissionsEnum.CREATE_DEAL])"
                :type="invalid ? 'button' : 'submit'"
                :loading="loading"
                :disabled="invalid"
                @click="invalid ? validator.$touch() : null"
            >
                {{ submitText }}
            </BaseButton>
        </template>
    </BaseForm>
</template>

<style lang="scss" scoped>
.deal-form {
    &__checkbox-box {
        margin-top: 20px;
        &:deep(.checkbox-box__wrap) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 14px 8px;
        }
        &:deep(.checkbox-block input + label) {
            font-size: 12px;
            line-height: 16px;
        }
    }
}
</style>
