export const universityBanner = {
    bgStyles: {
        color: '2f76ff',
        image: 'bg-partner-library'
    },
    title: 'Advantage Partner Program Library',
    text: 'Explore our product guides and unlock the full potential of the MSP360 Platform.',
    image: {
        width: 582,
        height: 313,
        alt: 'MSP University',
        image: 'banners/partner-library'
    }
}

export const universityCTA = {
    title: 'MSP University',
    text: 'Read and watch our best educational content about MSP360 Managed Backup, MSP marketing, sales, business development and backup',
    buttons: [
        {
            text: 'Visit MSP University',
            href: 'https://www.msp360.com/resources/msp-university/',
            size: 'sm',
            target: '_blank'
        }
    ]
}

export const tabs = [
    {
        id: 'backup',
        name: 'MSP360 Backup',
        resources: [
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/MBS-Business-1-pager.pdf?hsLang=en',
                img: 'backup-datasheet',
                title: 'Managed Backup - 1-page brochure',
                tag: 'Datasheet',
                products: ['backup']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/msp360-managed-backup-simple-reliable-es.pdf?hsLang=en',
                img: 'backup-datasheet',
                title: 'Managed Backup - 1-page brochure (ES)',
                tag: 'Datasheet',
                products: ['backup']
            },
            {
                href: 'https://5442029.fs1.hubspotusercontent-na1.net/hubfs/5442029/Partners/Resellers/Assets%20for%20partners/msp360-managed-backup-datasheet%20.pdf',
                img: 'backup-datasheet',
                title: 'MSP360 Managed Backup datasheet',
                tag: 'Datasheet',
                products: ['backup']
            },
            {
                href: 'https://5442029.fs1.hubspotusercontent-na1.net/hubfs/5442029/Partners/Resellers/Assets%20for%20partners/Backup-co-brandable-datasheet.pptx',
                img: 'backup-datasheet',
                title: 'MSP360 Backup datasheet (co-brandable)',
                tag: 'Datasheet',
                products: ['backup']
            },
            {
                href: 'https://5442029.fs1.hubspotusercontent-na1.net/hubfs/5442029/Partners/Resellers/Assets%20for%20partners/MSP360-Managed-Backup-AWS.pdf',
                img: 'backup-datasheet',
                title: 'MSP360 Managed Backup + AWS datasheet',
                tag: 'Datasheet',
                products: ['backup']
            },
            {
                href: 'https://5442029.fs1.hubspotusercontent-na1.net/hubfs/5442029/Partners/Resellers/Assets%20for%20partners/MSP360-Managed-Backup-Wasabi.pdf',
                img: 'backup-datasheet',
                title: 'MSP360 Managed Backup + Wasabi datasheet',
                tag: 'Datasheet',
                products: ['backup']
            },
            {
                href: 'https://5442029.fs1.hubspotusercontent-na1.net/hubfs/5442029/Partners/Resellers/Assets%20for%20partners/MSP360-Managed-Backup-Backblaze-B2.pdf',
                img: 'backup-datasheet',
                title: 'MSP360 Managed Backup + Backblaze B2 datasheet',
                tag: 'Datasheet',
                products: ['backup']
            },
            {
                href: 'https://youtu.be/1EuSkN6Dbpc',
                img: 'backup-video',
                title: 'Getting Started with MSP360 Managed Backup',
                tag: 'Video'
            },
            {
                href: 'https://www.youtube.com/watch?v=dvykDOSWKiA',
                img: 'backup-video2',
                title: 'MSP360 Managed Backup Explainer Video',
                tag: 'Video'
            },
            {
                href: 'https://www.msp360.com/resources/blog/getting-started-with-msp360-managed-backup-service/',
                img: 'backup-text-guide',
                title: 'Getting Started with MSP360 Managed Backup',
                tag: 'Text Guide',
                products: ['backup']
            },
            {
                href: 'https://www.msp360.com/download/msp360-managed-backup-vs-acronis.pdf?_gl=1*1mhqfwz*_gcl_au*MTY5NDI2ODczLjE3MjEyMjgzNjk.',
                img: 'backup-battle-cards',
                title: 'MSP360 Managed Backup vs Acronis',
                tag: 'Battle Cards',
                products: ['backup']
            },
            {
                href: 'https://www.msp360.com/download/msp360-managed-backup-vs-veeam.pdf?_gl=1*6d7mhq*_gcl_au*MTY5NDI2ODczLjE3MjEyMjgzNjk.',
                img: 'backup-battle-cards',
                title: 'MSP360 Managed Backup vs Veeam',
                tag: 'Battle Cards',
                products: ['backup']
            },
            {
                href: 'https://www.msp360.com/download/msp360-managed-backup-vs-altaro.pdf',
                img: 'backup-battle-cards',
                title: 'MSP360 Managed Backup vs Altaro',
                tag: 'Battle Cards',
                products: ['backup']
            },
            {
                href: 'https://www.msp360.com/download/msp360-managed-backup-vs-datto.pdf?_gl=1*135r4uo*_gcl_au*MTY5NDI2ODczLjE3MjEyMjgzNjk.',
                img: 'backup-battle-cards',
                title: 'MSP360 Managed Backup vs Datto',
                tag: 'Battle Cards',
                products: ['backup']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/MSP360%20Managed%20Backup%20For%20Microsoft%20365%20for%20Partners.pdf',
                img: 'backup-datasheet',
                title: 'Microsoft 365 1-pager',
                tag: 'Datasheet',
                products: ['backup']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/M365%20Shared%20Responsibility%20for%20Partners.pdf',
                img: 'backup-datasheet',
                title: 'Microsoft 365 Shared responsibility',
                tag: 'Datasheet',
                products: ['backup']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/Google%20Workspace%201-pager%20for%20partners.pdf',
                img: 'backup-datasheet',
                title: 'Google Workspace 1-pager',
                tag: 'Datasheet',
                products: ['backup']
            }
        ]
    },
    {
        id: 'rmm',
        name: 'MSP360 RMM',
        resources: [
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/msp360-rmm-simple-reliable.pdf?hsLang=en',
                img: 'rmm-datasheet',
                title: 'MSP360 RMM - 1-page brochure',
                tag: 'Datasheet',
                products: ['rmm']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/msp360-rmm-simple-reliable-es.pdf?hsLang=en',
                img: 'rmm-datasheet',
                title: 'MSP360 RMM - 1-page brochure (ES)',
                tag: 'Datasheet',
                products: ['rmm']
            },
            {
                href: 'https://get.msp360.com/hubfs/Datasheets/RMM.pdf?hsLang=en',
                img: 'rmm-datasheet',
                title: 'MSP360 RMM datasheet',
                tag: 'Datasheet',
                products: ['rmm']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/co-brandable-rmm-datasheet.pptx?hsLang=en',
                img: 'rmm-datasheet',
                title: 'MSP360 RMM datasheet (co-brandable)',
                tag: 'Datasheet',
                products: ['rmm']
            },
            {
                href: 'https://www.msp360.com/resources/blog/getting-started-with-msp360-remote-monitoring-and-management/',
                img: 'rmm-text-guide',
                title: 'Getting Started with MSP360 RMM',
                tag: 'Text Guide',
                products: ['rmm']
            },
            {
                href: 'https://youtu.be/DDhtXVd8tpo?si=-hiGWKN0sAkVnzJg',
                img: 'rmm-video',
                title: 'Getting Started with MSP360 RMM',
                tag: 'Video'
            },
            {
                href: 'https://youtu.be/8ymqKJub77M?si=VrPHWEhFyQgeBElC',
                img: 'rmm-video2',
                title: 'MSP360 RMM explainer video',
                tag: 'Video'
            },
            {
                href: 'https://www.msp360.com/download/msp360rmm-vs-syncro-comparison.pdf',
                img: 'rmm-battle-cards',
                title: 'MSP360 RMM vs Syncro',
                tag: 'Battle Cards',
                products: ['rmm']
            },
            {
                href: 'https://www.msp360.com/download/msp360rmm-vs-atera-comparison.pdf?_gl=1*t8ctye*_gcl_au*MTY5NDI2ODczLjE3MjEyMjgzNjk.',
                img: 'rmm-battle-cards',
                title: 'MSP360 RMM vs Atera',
                tag: 'Battle Cards',
                products: ['rmm']
            },
            {
                href: 'https://www.msp360.com/download/msp360rmm-vs-datto.pdf',
                img: 'rmm-battle-cards',
                title: 'MSP360 RMM vs Datto',
                tag: 'Battle Cards',
                products: ['rmm']
            },
            {
                href: 'https://www.msp360.com/download/msp360rmm-vs-ninjaonermm-comparison.pdf',
                img: 'rmm-battle-cards',
                title: 'MSP360 RMM vs NinjaOne',
                tag: 'Battle Cards',
                products: ['rmm']
            }
        ]
    },
    {
        id: 'connect',
        name: 'MSP360 Connect',
        resources: [
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/msp360-connect-simple-reliable.pdf',
                img: 'connect-datasheet',
                title: 'MSP360 Connect - 1-page brochure',
                tag: 'Datasheet',
                products: ['connect']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/msp360-connect-simple-reliable-es.pdf',
                img: 'connect-datasheet',
                title: 'MSP360 Connect - 1-page brochure (ES)',
                tag: 'Datasheet',
                products: ['connect']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/msp360-connect-datasheet.pdf',
                img: 'connect-datasheet',
                title: 'MSP360 Connect Datasheet',
                tag: 'Datasheet',
                products: ['connect']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/msp360-connect-co-brandable-datasheet.pptx',
                img: 'connect-datasheet',
                title: 'MSP360 Connect Datasheet (co-brandable)',
                tag: 'Datasheet',
                products: ['connect']
            },
            {
                href: 'https://www.msp360.com/resources/blog/getting-started-with-msp360-connect-managed-console/',
                img: 'connect-text-guide',
                title: 'Getting Started with MSP360 Managed Connect',
                tag: 'Text Guide',
                products: ['connect']
            },
            {
                href: 'https://youtu.be/sLxbyKJJvoY?si=CLdjl5hbppyzZHTR',
                img: 'connect-video',
                title: 'Getting Started with MSP360 Managed Connect',
                tag: 'Video'
            },
            {
                href: 'https://youtu.be/5IOffLh6nIs',
                img: 'connect-video2',
                title: 'MSP360 Managed Connect explainer video',
                tag: 'Video'
            },
            {
                href: 'https://youtu.be/pc6_CWU5O3g',
                img: 'connect-video2',
                title: 'Meet MSP360 Connect (ES)',
                tag: 'Video'
            },
            {
                href: 'https://www.msp360.com/download/msp360-vs-teamviewer-comparison.pdf',
                img: 'connect-battle-cards',
                title: 'MSP360 Connect vs Teamviewer',
                tag: 'Battle Cards',
                products: ['connect']
            },
            {
                href: 'https://www.msp360.com/download/msp360-vs-splashtop-comparison.pdf',
                img: 'connect-battle-cards',
                title: 'MSP360 Connect vs Splashtop',
                tag: 'Battle Cards',
                products: ['connect']
            },
            {
                href: 'https://www.msp360.com/download/msp360-vs-anydesk-comparison.pdf',
                img: 'connect-battle-cards',
                title: 'MSP360 Connect vs Anydesk',
                tag: 'Battle Cards',
                products: ['connect']
            },
            {
                href: 'https://www.msp360.com/download/msp360-vs-connectwisecontrolsupport-comparison.pdf?_gl=1*1i59i9k*_gcl_au*MTY5NDI2ODczLjE3MjEyMjgzNjk.',
                img: 'connect-battle-cards',
                title: 'MSP360 Connect vs ConnectWise Control Support',
                tag: 'Battle Cards',
                products: ['connect']
            },
            {
                href: 'https://www.msp360.com/download/msp360-vs-logmein-comparison.pdf',
                img: 'connect-battle-cards',
                title: 'MSP360 Connect vs LogMeIn (GoTo)',
                tag: 'Battle Cards',
                products: ['connect']
            }
        ]
    },
    {
        id: 'platform',
        name: 'MSP360 Platform',
        resources: [
            {
                href: 'https://5442029.fs1.hubspotusercontent-na1.net/hubfs/5442029/Partners/Resellers/Assets%20for%20partners/storage-providers-price-comparison.pdf',
                img: 'backup-datasheet',
                title: 'Storage providers comparison',
                tag: 'Datasheet',
                products: ['backup']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/msp360-advantage-partner-program-brochure.pdf?hsLang=en',
                img: 'di-datasheet',
                title: 'Advantage Partner Program Brochure',
                tag: 'Datasheet'
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Advantage-Partner-Program-Guide.pdf?hsLang=en',
                img: 'di-datasheet',
                title: 'Partner Program Guide',
                tag: 'Datasheet'
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/advantage-partner-library-tiered-pricing-list.pdf?hsLang=en',
                img: 'di-datasheet',
                title: 'Reseller Pricing Tiers',
                tag: 'Datasheet'
            },
            {
                href: 'https://www.msp360.com/download/MSP360-customer-support-reference-guide.pdf',
                img: 'di-datasheet',
                title: 'Customer Support Reference Guide',
                tag: 'Text Guide'
            },
            {
                href: 'https://5442029.fs1.hubspotusercontent-na1.net/hubfs/5442029/Terms%20and%20Conditions/MSP360%20Terms%20and%20Conditions.pdf',
                img: 'di-datasheet',
                title: 'MSP360 Terms and Conditions',
                tag: 'Terms & Conditions',
                products: ['backup', 'rmm', 'connect']
            },
            {
                href: 'https://5442029.fs1.hubspotusercontent-na1.net/hubfs/5442029/Terms%20and%20Conditions/MSP360%20Deep%20Instinct%20Terms%20and%20Conditions.pdf',
                img: 'di-datasheet',
                title: 'Deep Instinct Terms and Conditions',
                tag: 'Terms & Conditions',
                products: ['di']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/msp360-platform-simple-reliable.pdf',
                img: 'di-datasheet',
                title: 'MSP360 Platform - 1-page brochures',
                tag: 'Datasheet',
                products: ['backup', 'rmm', 'connect']
            },
            {
                href: 'https://www.msp360.com/msp360-videos/customer-success-stories/',
                img: 'products-video',
                title: 'What Our Customers Say? (Video)',
                tag: 'Video'
            },
            {
                href: 'https://www.msp360.com/resources/blog/bestrx-chooses-msp360-rmm-to-stay-within-one-ecosystem/?_gl=1*ayoams*_gcl_au*MTY5NDI2ODczLjE3MjEyMjgzNjk.',
                img: 'di-datasheet',
                title: 'BestRx Chooses MSP360',
                tag: 'Text Guide',
                products: ['backup', 'rmm', 'connect']
            },
            {
                href: 'https://www.msp360.com/resources/blog/disaster-recovery-for-connecticut-msp/?_gl=1*du1054*_gcl_au*MTY5NDI2ODczLjE3MjEyMjgzNjk.',
                img: 'backup-text-guide',
                title: 'Replacing SolarWinds with MSP360',
                tag: 'Text Guide',
                products: ['backup']
            },
            {
                href: 'https://www.msp360.com/resources/blog/success-story-sausalito-networking/?_gl=1*du1054*_gcl_au*MTY5NDI2ODczLjE3MjEyMjgzNjk.',
                img: 'backup-text-guide',
                title: 'MSP Triples Customers with MSP360 and Wasabi',
                tag: 'Text Guide',
                products: ['backup']
            },
            {
                href: 'https://www.msp360.com/company/media/downloads/',
                img: 'backup-logos',
                title: 'MSP360 Downloadable Assets',
                tag: 'Logos',
                products: ['backup', 'rmm', 'connect']
            }
        ]
    },
    {
        id: 'deep-instinct',
        name: 'Deep Instinct',
        resources: [
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/MSP360%20Powered%20%20By%20Deep%20Instinct.pdf',
                img: 'di-datasheet',
                title: 'MSP360 Powered by Deep Instinct (EN)',
                tag: 'Datasheet',
                products: ['di']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/MSP360%20impulsado%20por%20Deep%20Instinct.pdf',
                img: 'di-datasheet',
                title: 'MSP360 Powered by Deep Instinct (ES)',
                tag: 'Datasheet',
                products: ['di']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/Assets%20for%20partners/MSP360%20desenvolvido%20pela%20Deep%20Instinct.pdf',
                img: 'di-datasheet',
                title: 'MSP360 Powered by Deep Instinct  (PT)',
                tag: 'Datasheet',
                products: ['di']
            },
            {
                href: 'https://5442029.fs1.hubspotusercontent-na1.net/hubfs/5442029/Partners/Resellers/Assets%20for%20partners/MSP360-Managed-Backup-and-Deep-Instinct-Simple-Reliable-Secure.pdf',
                img: 'di-datasheet',
                title: 'MSP360 Managed Backup + Deep Instinct',
                tag: 'Datasheet',
                products: ['backup', 'di']
            },
            {
                href: 'https://get.msp360.com/hubfs/Partners/Resellers/MSP360-RMM-and-Deep-Instinct-Simple-Reliable-Secure.pdf?hsLang=en',
                img: 'di-datasheet',
                title: 'MSP360 RMM + Deep Instinct',
                tag: 'Datasheet',
                products: ['rmm', 'di']
            }
        ]
    },
    {
        id: 'tech-training',
        name: 'Tech Training',
        resources: [
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration?hsLang=en',
                img: 'products-video',
                title: 'MSP360 Platform Overview',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-2?hsLang=en',
                img: 'products-video',
                title: 'Basic Concepts of the Service',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-3?hsLang=en',
                img: 'products-video',
                title: 'Web Console: General Settings',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-4?hsLang=en',
                img: 'products-video',
                title: 'Web Console: Storage',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-5?hsLang=en',
                img: 'products-video',
                title: 'Web Console: Organization Settings',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-6?hsLang=en',
                img: 'products-video',
                title: 'Web Console: Downloads and Deployment',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-7?hsLang=en',
                img: 'backup-video',
                title: 'Backup and Restore Types and Principles',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-8?hsLang=en',
                img: 'backup-video',
                title: 'Backup Modes and File-level Backup and Restore',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-9?hsLang=en',
                img: 'backup-video',
                title: 'Demo: File-Level Backup and Restore Plans',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-10?hsLang=en',
                img: 'backup-video',
                title: 'Image-Based Backup and Restore',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-11?hsLang=en',
                img: 'backup-video',
                title: 'Demo: Image-Based Backup and Restore Plans',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-12?hsLang=en',
                img: 'backup-video',
                title: 'Microsoft SQL Backup and Restore Plans',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-13?hsLang=en',
                img: 'backup-video',
                title: 'Microsoft Exchange Backup and Restore Plans',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-14?hsLang=en',
                img: 'backup-video',
                title: 'Advanced Options of the Web Console & Agent',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-20?hsLang=en',
                img: 'backup-video',
                title: 'Microsoft 365/Google Workspace Backup & Restore',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-15?hsLang=en',
                img: 'products-video',
                title: 'Workflows 1: Configuration, Monitoring, Troubleshooting',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-16?hsLang=en',
                img: 'products-video',
                title: 'Workflows 2: On/Offboarding Customers, and more',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-17?hsLang=en',
                img: 'products-video',
                title: 'Workflows 3 - Configuration Audit + Demo',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-18?hsLang=en',
                img: 'products-video',
                title: 'Workflows 4 - Disaster Recovery + Demo',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-19?hsLang=en',
                img: 'connect-video',
                title: 'MSP360 Connect + Demo',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-module-21?hsLang=en',
                img: 'rmm-video',
                title: 'MSP360 RMM + Demo',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-spanish?hsLang=en',
                img: 'products-video',
                title: 'Platform Overview (ES)',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-spanish-2?hsLang=en',
                img: 'products-video',
                title: 'Preparing Environment (ES)',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-spanish-3?hsLang=en',
                img: 'products-video',
                title: 'MSP360 Web Console Settings (ES)',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-spanish-4?hsLang=en',
                img: 'products-video',
                title: 'MSP360 Web Console Part 1 (ES)',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-spanish-5?hsLang=en',
                img: 'products-video',
                title: 'MSP360 Web Console Part 2 (ES)',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-spanish-6?hsLang=en',
                img: 'products-video',
                title: 'Downloads and Deployment (ES)',
                tag: 'Video'
            },
            {
                href: 'https://get.msp360.com/msp360-technical-demonstration-spanish-7?hsLang=en',
                img: 'backup-video',
                title: 'MSP360 Backup and Restore (ES)',
                tag: 'Video'
            },
            {
                href: 'https://youtu.be/S2c-oebm4Yg?si=NPVZspqu45Y3dw3m',
                img: 'products-video',
                title: 'Sales Objections During Lead Qualification',
                tag: 'Video'
            }
        ]
    },
    {
        id: 'certified-msp360-sales',
        name: 'Certified MSP360&nbsp;Sales',
        resources: [
            {
                title: 'Introduction: 1a',
                img: 'products-video',
                href: 'https://youtu.be/yHpWWJf9FGs?si=E1gZNCzHdMWt3FWy ',
                tag: 'Video'
            },
            {
                title: 'MSP360 Storage accounts: 2a',
                img: 'products-video',
                href: 'https://youtu.be/ixIGVvY4pnM?si=1yhi9C5xiW6gEd9L ',
                tag: 'Video'
            },
            {
                title: 'Companies and Users: 2b',
                img: 'products-video',
                href: 'https://youtu.be/6n8ufwdx2IY',
                tag: 'Video'
            },
            {
                title: 'Computers tab overview: 2c',
                img: 'products-video',
                href: 'https://youtu.be/szKZBjTYwLw?si=Msi-E92mJpmeJx9O ',
                tag: 'Video'
            },
            {
                title: 'Reports: 2d',
                img: 'products-video',
                href: 'https://youtu.be/nRjoQQxKlME?si=CducdAnzoS99DrTF',
                tag: 'Video'
            },
            {
                title: 'M365/Google Workspace Product Overview: 3a',
                img: 'backup-video',
                href: 'https://youtu.be/OdIGSs4nwc8',
                tag: 'Video'
            },
            {
                title: 'M365/Google Workspace Main Features: 3b ',
                img: 'backup-video',
                href: 'https://youtu.be/-Lfn277WSkI',
                tag: 'Video'
            },
            {
                title: 'General overview of the backup plans: 4a',
                img: 'backup-video',
                href: 'https://youtu.be/x41asc6hQqA',
                tag: 'Video'
            },
            {
                title: 'General overview of the main benefits: 4b',
                img: 'backup-video',
                href: 'https://youtu.be/P6butZH3Sl4',
                tag: 'Video'
            },
            {
                title: 'General overview of the main benefits: Restoration options: 4bi',
                img: 'backup-video',
                href: 'https://youtu.be/-h56rCRMAp8',
                tag: 'Video'
            },
            {
                title: 'Main Features: Backup options: Object lock, GFS, FFI: 5a',
                img: 'backup-video',
                href: 'https://youtu.be/Q7z-co0N_WQ',
                tag: 'Video'
            },
            {
                title: 'RMM Main concept and features: 6a',
                img: 'rmm-video',
                href: 'https://youtu.be/2ubBcekOWJA',
                tag: 'Video'
            },
            {
                title: 'RMM  Quick Product Overview: 6b',
                img: 'rmm-video',
                href: 'https://youtu.be/0g6V0XzCxHA',
                tag: 'Video'
            },
            {
                title: 'RMM Group actions: 6c',
                img: 'rmm-video',
                href: 'https://youtu.be/ukCrqUXP7Ms',
                tag: 'Video'
            },
            {
                title: 'MSP360 Connect: Product Overview',
                img: 'connect-video',
                href: 'https://youtu.be/ABrQpYHwMD8',
                tag: 'Video'
            },
            {
                title: 'Licenses Overview: 7a',
                img: 'products-video',
                href: 'https://youtu.be/CUhjVlwPUzg',
                tag: 'Video'
            },
            {
                title: 'Objective Handling: 8a',
                img: 'products-video',
                href: 'https://youtu.be/S2c-oebm4Yg?si=w8TPc17cOqC7fUqL',
                tag: 'Video'
            },
            {
                title: 'Competitors battle card',
                img: 'products-video',
                href: 'https://youtu.be/uGgZbJqVVdc',
                tag: 'Video'
            }
        ]
    }
]
