import { authAPI } from '@/api/auth-api'
import { notificationTypes } from '@/constants/notificationTypes'
import { pushNotification } from '@/utils/pushNotification'
import { userCredentialActions } from '@/constants/user-settings'

export const auth = {
    state: () => ({
        user: null,
        users: [],
        usersLoading: false,
        usersTotalCount: null
    }),
    getters: {
        currentUser: (state) => state.user,
        isValidCurrentRole: (state) => (validRoles) => {
            return validRoles.includes(state.user?.role)
        },
        userRole: (state) => state.user?.role ?? null,
        isMBSUser: (state) => state.user?.isMBSUser ?? null,
        userAccountType: (state) => state.user?.accountType ?? null,
        fullName: (state) => state.user?.name || 'Partner',
        firstName: (state) => {
            const fullName = state.user?.name
            const firstName = fullName?.split(' ')[0]
            return firstName || 'Partner'
        },
        email: (state) => state.user?.email || 'dev-test@.gmail.com',
        users: (state) => state.users,
        usersLoading: (state) => state.usersLoading,
        usersTotalCount: (state) => state.usersTotalCount
    },
    mutations: {
        SET_CURRENT_USER(state, payload) {
            state.user = { ...payload }
        },
        SET_USERS(state, payload) {
            state.users = [...state.users, ...payload]
        },
        RESET_USERS(state) {
            state.users = []
        },
        REGISTER_USER(state, payload) {
            state.users = [payload, ...state.users]
        },
        SET_USERS_LOADING(state, isLoading) {
            state.usersLoading = isLoading
        },
        SET_USERS_TOTAL_COUNT(state, payload) {
            state.usersTotalCount = payload
        },
        UPDATE_USER(state, newUser) {
            state.users = state.users.map((user) => {
                return user._id === newUser._id ? newUser : user
            })
        }
    },
    actions: {
        signup(cxt, data) {
            return authAPI.signup(data)
        },
        signin(cxt, data) {
            return authAPI.signin(data)
        },
        forgotPassword(cxt, data) {
            return authAPI.forgotPassword(data)
        },
        resetPassword(cxt, data) {
            return authAPI.resetPassword(data)
        },
        async registerUser(cxt, data) {
            try {
                const res = await authAPI.registerUser(data)
                console.log('registerUser res: ', res)

                const usersTotalCount = cxt.getters.users.length
                console.log('usersTotalCount: ', usersTotalCount)

                if (usersTotalCount && res.data) {
                    console.log('newUser: ', res.data)
                    cxt.commit('REGISTER_USER', res.data)
                    cxt.commit('SET_USERS_TOTAL_COUNT', usersTotalCount + 1)
                }

                return res
            } catch (err) {
                console.log('registerUser API err: ', err)
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        async fetchCurrentUser(cxt) {
            try {
                const res = await authAPI.fetchCurrentUser()
                console.log('SET_CURRENT_USER: ', res)
                if (res.status === 200 && res.data) {
                    cxt.commit('SET_CURRENT_USER', res.data)
                }
                return res
            } catch (error) {
                return error
            }
        },
        async fetchUsers(cxt, params) {
            cxt.commit('SET_USERS_LOADING', true)
            try {
                const res = await authAPI.fetchUsers(params)
                if (res.results) {
                    cxt.commit('SET_USERS', res.results)
                    cxt.commit(
                        'SET_USERS_TOTAL_COUNT',
                        res._meta[0]?.count ?? 0
                    )
                } else {
                    pushNotification({
                        message: `Unknown error in fetching the users`,
                        type: notificationTypes.ERROR
                    })
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
            cxt.commit('SET_USERS_LOADING', false)
        },
        async setUserCredentials(cxt, params) {
            try {
                const res = await authAPI.setUserCredentials(params)
                console.log('setUserCredentials STORE: ', res)
                if (res?.success) {
                    const user = cxt.getters.users.find(
                        (u) => u._id === params.id
                    )
                    if (params.action === userCredentialActions.REMOVE) {
                        cxt.commit('UPDATE_USER', {
                            ...user,
                            deleted: true
                        })
                    }
                } else if (!res?.errInfo?.isError) {
                    throw new Error('Unknown error in setting access rights')
                }
            } catch (err) {
                pushNotification({
                    message: `<span>${err.name}</span>: ${err.message}`,
                    type: notificationTypes.ERROR
                })
            }
        },
        async logout() {
            try {
                const res = await authAPI.logout()
                console.log('res.isLogout: ', res.isLogout)
                if (res.isLogout) {
                    window.location.href = '/account/signin'
                    return
                }
                throw new Error()
            } catch {
                pushNotification({
                    message: `<span>Error</span>: failed to logout`,
                    type: notificationTypes.ERROR
                })
            }
        }
    }
}
