<script setup>
import { computed } from 'vue'
const props = defineProps({
    theme: { type: String, default: 'default' },
    cacheLifetime: { type: String, default: '0' },
    options: { type: Object, default: () => {} }
})
const classes = computed(() => {
    const classes = ['base-tabs']

    classes.push(`base-tabs_theme-${props.theme}`)

    return classes
})
</script>

<script>
export default {
    name: 'BaseTabs'
}
</script>

<template>
    <VueTabs
        :cache-lifetime="cacheLifetime"
        :options="options"
        :wrapper-class="classes"
    >
        <slot />
    </VueTabs>
</template>

<style lang="scss" scoped>
.base-tabs {
    &:deep {
        .tabs-component-tabs {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }
    &_theme {
        &-default {
            &:deep {
                .tabs-component-tabs {
                    display: flex;
                    gap: 16px;
                }
                .tabs-component-tab-a {
                    display: block;
                    text-decoration: none;
                    color: #969696;
                    background-color: #e7e9f2;
                    padding: 10px;
                    text-align: center;
                    transition: 0.2s linear;
                    border-radius: 20px 20px 0 0;
                    min-width: 180px;
                    &.is-active,
                    &:hover {
                        background-color: #ffffff;
                        color: #f74e00;
                    }
                }
                .tabs-component-panels {
                    background-color: #ffffff;
                    border-radius: 0 20px 20px 20px;
                    width: fit-content;
                }
            }
        }
        &-line {
            &:deep {
                .tabs-component-tabs {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 2px solid #ced4da;
                }
                .tabs-component-tab {
                    width: 100%;
                    max-width: max-content;
                    color: #969696;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.416px;
                    position: relative;
                    text-align: center;
                    align-content: flex-end;
                    @include media_max_xl {
                        font-size: 14px;
                        line-height: 18px;
                    }
                    @include media_lg {
                        max-width: min-content;
                    }
                    &:after {
                        content: '';
                        width: 100%;
                        height: 2px;
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        transform: scale(0);
                        transition: 0.3s ease-out;
                        background: #f74e00;
                        border-radius: 12px;
                    }
                    &.is-active,
                    &:hover {
                        color: #f74e00;
                        &:after {
                            transform: scale(1);
                        }
                    }
                }
                .tabs-component-tab-a {
                    display: block;
                    padding: 12px 24px;
                    text-decoration: none;
                    color: inherit;
                    @include media_max_xl {
                        padding: 12px 16px;
                    }
                }
                .tabs-component-panels {
                    padding-top: 32px;
                }
            }
        }
    }
}
</style>
