export const countryList = require('country-list')
const states = require('states-us')

const rewritedContries = {
    ['United Kingdom of Great Britain and Northern Ireland']: 'United Kingdom',
    ['United States of America']: 'United States'
}

export const getCountryOptions = (params) => {
    const allCountryNames = countryList.getNames()
    let preferredCountries = []
    let filteredCountries = []

    if (params?.preferredCountries?.length) {
        preferredCountries = params.preferredCountries.map((iso) => {
            return countryList.getName(iso)
        })
        filteredCountries = allCountryNames.filter(
            (c) => !preferredCountries.includes(c)
        )
    }

    const preparedCountryNames = params?.preferredCountries?.length
        ? [...preferredCountries, ...filteredCountries]
        : allCountryNames

    const countryOptions = preparedCountryNames.map((country) => {
        const locCountry = rewritedContries[country] ?? country

        return {
            text: locCountry,
            value: locCountry
        }
    })

    return countryOptions
}

export const getStateOptions = () => {
    const stateOptions = states.default.map((state) => ({
        text: state.name,
        value: state.name
    }))
    return stateOptions
}
